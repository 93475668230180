<style lang="less">
.orderList {
  .ivu-table .table-info-column-status {
    background-color: #118876;
    color: #fff;
  }
}
.modalOrderList {
  padding: 0 15px;
  line-height: 2;
  .title {
    height: 14px;
    line-height: 14px;
    border-left: 10px #48cec3 solid;
    padding-left: 5px;
    color: #999999;
    font-weight: bold;
    margin: 15px 0;
  }
  .label {
    font-weight: bold;
    color: #666666;
  }
}
</style>
<template>
  <div class="orderList">
    <div class="table-button">
      <!-- <Button type="primary" @click="toHandleOrder()">处理</Button> -->
      <Button type="primary" @click="toSendMoney()">分账</Button>
      <!-- <Button type="primary">导入</Button>
      <Button type="primary">删除</Button>
      <Button type="primary">下载</Button> -->
    </div>
    <div class="table-input">
      <Select v-model="param.status" clearable placeholder="订单状态">
        <Option v-for="item in statusList" :value="item.id" :key="item.id">{{item.name}}</Option>
      </Select>
      <Select v-model="param.type" clearable placeholder="订单类型">
        <Option v-for="item in orderTypeList" :value="item.id" :key="item.id">{{item.name}}</Option>
      </Select>
      <Input v-model="param.nickname" clearable placeholder="商家" />
      <Input v-model="param.name" clearable placeholder="商品名称" />
      <Input v-model="param.code" clearable placeholder="订单号" />
      <Button type="primary" @click="search()">搜索</Button>
    </div>
    <Table :columns="columns" :data="data" @on-selection-change="changeSelection">

      <template slot-scope="{ row, index }" slot="type">
        <span v-if="row.type==item.id" v-for="item,index in orderTypeList" :key="index">{{item.name}}</span>
      </template>

      <template slot-scope="{ row, index }" slot="status">
        <span v-if="row.status==item.id" v-for="item,index in statusList" :key="index">{{item.name}}</span>
      </template>

      <template slot-scope="{ row, index }" slot="payMethod">
        <span v-if="row.payMethod==item.id" v-for="item,index in payMethodList" :key="index">{{item.name}}</span>
      </template>

      <template slot-scope="{ row, index }" slot="payMoney">
        <span>{{row.payMoney/100}}<span v-if="row.payMoney/100>row.totalPrice" style="font-size:12px">(合并付款)</span></span>
      </template>

      <template slot-scope="{ row, index }" slot="payTime">
        <span>{{MyUtil.dateToString(row.payTime)}}</span>
      </template>

      <template slot-scope="{ row, index }" slot="createTime">
        <span>{{MyUtil.dateToString(row.createTime)}}</span>
      </template>

      <template slot-scope="{ row, index }" slot="action">
        <Button type="primary" size="small" @click="showModalOne(row)">更多</Button>
        <Button type="error" size="small" @click="showModalThree(row)" :disabled="row.payMoney<=0||row.status==101">退款</Button>
        <!-- <Button type="error" size="small" @click="cancelOrder(row)" :disabled="row.status==102">取消</Button> -->
      </template>
    </Table>
    <Page :total="total" :current="param.page" :page-size="param.rows" show-total show-sizer @on-change="changePage" @on-page-size-change="changePageSize"></Page>

    <!-- 模态框 -->
    <Modal v-model="modalOne" title="更多信息" width="800">

      <div class="modalOrderList" v-if="formOne.type==1">
        <Row>
          <Col span="12"><span class="label">商品ID：</span>{{formOne.goodsId}}</Col>
          <Col span="12"><span class="label">商品名称：</span>{{formOne.name}}</Col>
        </Row>
        <Row><span class="label">商品图片：</span><img :src="item" style="width: 100px;height: 100px;margin:5px 5px 0 5px;cursor: zoom-in;" v-for="item,index in formOne.urlArr" :key="index" @click="MyUtil.showBigImg(item)" />
        </Row>
        <Divider plain orientation="left">收货信息</Divider>
        <Row>
          <Col span="12"><span class="label">收货人：</span>{{formOne.contacts}}</Col>
          <Col span="12"><span class="label">收货手机：</span>{{formOne.phone}}</Col>
          <Col span="24"><span class="label">收货地址：</span>{{formOne.address}}</Col>
        </Row>
        <Divider plain orientation="left">物流信息</Divider>
        <Row>
          <Col span="24" v-if="formOne.typeReceive==0"><span class="label">收货方式：</span>现场</Col>
          <Col span="24" v-if="formOne.typeReceive==1"><span class="label">收货方式：</span>快递</Col>
          <Col span="12"><span class="label">快递公司：</span>{{formOne.expressCompany}}</Col>
          <Col span="12"><span class="label">快递单号：</span>{{formOne.expressCode}}</Col>
          <Col span="12"><span class="label">发货时间：</span>{{MyUtil.dateToString(formOne.sendGoodsTime)}}</Col>
          <Col span="12"><span class="label">收货时间：</span>{{MyUtil.dateToString(formOne.receiveGoodsTime)}}</Col>
        </Row>
      </div>

      <div class="modalOrderList" v-if="formOne.type==2">
        <Row>
          <Col span="12"><span class="label">活动ID：</span>{{formOne.goodsId}}</Col>
          <Col span="12"><span class="label">活动名称：</span>{{formOne.name}}</Col>
        </Row>
        <Row><span class="label">活动图片：</span><img :src="item" style="width: 100px;height: 100px;margin:5px 5px 0 5px;cursor: zoom-in;" v-for="item,index in formOne.urlArr" :key="index" @click="MyUtil.showBigImg(item)" />
        </Row>
        <Divider plain orientation="left">活动信息</Divider>
        <Row>
          <Col span="12"><span class="label">开始时间：</span>{{MyUtil.dateToString(formOne.startTime)}}</Col>
          <Col span="12"><span class="label">结束时间：</span>{{MyUtil.dateToString(formOne.endTime)}}</Col>
          <Col span="12"><span class="label">活动地点：</span>{{formOne.landmark}}</Col>
          <Col span="12"><span class="label">活动地址：</span>{{formOne.address}}</Col>
        </Row>
        <Divider plain orientation="left">使用信息</Divider>
        <Row>
          <!-- <Col span="12"><span class="label">购买数量：</span>{{formOne.buyCount}}</Col> -->
          <Col span="12" v-if="formOne.statusUse==0"><span class="label">使用状态：</span>待使用（存在票未使用）</Col>
          <Col span="12" v-if="formOne.statusUse==1"><span class="label">使用状态：</span>已使用（全部票已使用）</Col>
          <Col span="12" v-if="formOne.statusUse==2"><span class="label">使用状态：</span>已过期（存在票已过期）</Col>
        </Row>

        <table border="1" style="text-align: center" v-if="formOne.listTicket">
          <th width="150px">券码</th>
          <th width="200px">规格</th>
          <th width="100px">状态</th>
          <th width="200px">使用时间</th>
          <tr v-for="item,index in formOne.listTicket" :key="index">
            <td>
              {{item.ticketCode}}
            </td>
            <td>
              {{item.standard}}
            </td>
            <td>
              <span v-if="item.statusUse==0">未使用</span>
              <span v-if="item.statusUse==1">已使用</span>
              <span v-if="item.statusUse==2">已过期</span>
            </td>
            <td>
              {{MyUtil.dateToString(item.useTime)}}
            </td>
          </tr>
        </table>
      </div>
      <Divider plain orientation="left">支付信息</Divider>
      <Row class="modalOrderList">
        <Col span="12" v-if="formOne.payMethod==0"><span class="label">支付方式：</span>未支付</Col>
        <Col span="12" v-if="formOne.payMethod==1"><span class="label">支付方式：</span>微信</Col>
        <Col span="12" v-if="formOne.payMethod==2"><span class="label">支付方式：</span>支付宝</Col>

        <Col span="12"><span class="label">支付金额：</span>{{formOne.payMoney/100}}元</Col>
        <Col span="12"><span class="label">支付单号：</span>{{formOne.payCode}}</Col>
        <Col span="12"><span class="label">支付时间：</span>{{MyUtil.dateToString(formOne.payTime)}}</Col>
      </Row>
      <Divider plain orientation="left">退款信息</Divider>
      <Row class="modalOrderList">
        <Col span="12" v-if="formOne.refundMethod==0"><span class="label">退款方式：</span>未退款</Col>
        <Col span="12" v-if="formOne.refundMethod==1"><span class="label">退款方式：</span>微信</Col>
        <Col span="12" v-if="formOne.refundMethod==2"><span class="label">退款方式：</span>支付宝</Col>

        <Col span="12"><span class="label">退款金额：</span>{{formOne.refundMoney/100}}元</Col>
        <Col span="12"><span class="label">退款单号：</span>{{formOne.refundCode}}</Col>
        <Col span="12"><span class="label">退款时间：</span>{{MyUtil.dateToString(formOne.refundTime)}}</Col>
      </Row>

      <div class="modalOrderList" v-if="formOne.sendMoneyTime">
        <Divider plain orientation="left">分账信息</Divider>
        <Row><span class="label">分账时间：</span>{{MyUtil.dateToString(formOne.sendMoneyTime)}}</Row>
        <Row><span class="label">分账凭证：</span><img :src="item" style="width: 100px;height: 100px;margin:5px 5px 0 5px;cursor: zoom-in;" v-for="item,index in formOne.sendMoneyUrlArr" :key="index"
            @click="MyUtil.showBigImg(item)" />
        </Row>
      </div>

      <div slot="footer">
        <Button @click="value=>this.modalOne=false">关闭</Button>
      </div>
    </Modal>

    <!-- 模态框 -->
    <Modal v-model="modalTwo" title="分账">
      <Upload :show-upload-list="true" :default-file-list="uploadList" :action="uploadUrl" :data="uploadData" :max-size="10240" :on-exceeded-size="()=> this.$Message.warning('文件大小不能超过10M')"
        :before-upload="handleBeforeUpload" :on-progress="handleOnProgress" :on-success="handleOnSuccess">
        <Button :disabled="(uploadPercent>0&&uploadPercent<100) || uploadList.length>=9">上传凭证</Button><span style="font-size:12px;color:#999999">最多上传9个文件</span>
      </Upload>
      <div slot="footer">
        <Button @click="value=>this.modalTwo=false">关闭</Button>
        <Button type="primary" @click="sendMoneyForWeb()">确定</Button>
      </div>
    </Modal>

    <!-- 模态框 -->
    <Modal v-model="modalThree" title="发起退款">
      <div style="color:red;margin-bottom:5px;">* 退款订单号：{{refundsCode}}</div>
      <div style="color:red;margin-bottom:5px;">* 退款到账需要一段时间（30秒内）！</div>
      <div style="color:red;margin-bottom:5px;">* 如果长时间没到账，请前往支付平台查询相关账目！</div>
      退款金额：
      <InputNumber v-model="refundsMoney" :min="0.01"></InputNumber>元
      <Spin fix v-if="loadingCheck">已成功发起退款！退款中，请稍后... ({{loadingCheckNum}})</Spin>
      <div slot="footer">
        <Button @click="value=>this.modalThree=false">关闭</Button>
        <Button type="primary" @click="refundsOrder()" :loading="loadingThree">确定</Button>
      </div>
    </Modal>

  </div>
</template>

<script>
export default {
  data() {
    return {
      MyUtil: this.MyUtil,
      orderTypeList: [
        { id: 1, name: "商品" },
        { id: 2, name: "活动" },
      ],
      statusList: [
        { id: 0, name: "未付款" },
        { id: 1, name: "已付款" },
        { id: 2, name: "待分账" },
        { id: 3, name: "已完成" },
        { id: 101, name: "已退款" },
      ],
      payMethodList: [
        { id: 0, name: "无" },
        { id: 1, name: "微信支付" },
        { id: 2, name: "支付宝支付" },
        { id: 3, name: "线下支付" },
      ],
      //列表
      deleteIds: [],
      param: {
        name: null,
        page: 1,
        rows: 10,
      },
      total: 0,
      data: [],
      columns: [
        {
          type: "selection",
          fixed: "left",
          width: 50,
        },
        {
          title: "订单号",
          key: "code",
          width: 150,
        },
        {
          title: "订单类型",
          slot: "type",
          width: 100,
        },
        {
          title: "商品名称",
          key: "name",
          width: 100,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "规格",
          key: "standard",
          width: 80,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "购买数量",
          key: "buyCount",
          width: 100,
        },
        {
          title: "商品单价(元)",
          key: "unitPrice",
          width: 120,
        },
        {
          title: "订单金额(元)",
          key: "totalPrice",
          width: 120,
        },
        {
          title: "实际支付(元)",
          slot: "payMoney",
          width: 180,
        },
        {
          title: "支付方式",
          slot: "payMethod",
          width: 120,
        },
        {
          title: "支付订单号",
          key: "payCode",
          width: 120,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "支付时间",
          slot: "payTime",
          width: 180,
        },
        {
          title: "下单时间",
          slot: "createTime",
          width: 180,
        },
        {
          title: "商家名称",
          key: "sellerNickname",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "商家电话",
          key: "sellerPhone",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "商家微信",
          key: "sellerWxCode",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "买家名称",
          key: "buyerNickname",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "买家电话",
          key: "buyerPhone",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "买家微信",
          key: "buyerWxCode",
          width: 150,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "订单状态",
          slot: "status",
          fixed: "right",
          width: 120,
        },
        {
          title: "操作",
          slot: "action",
          fixed: "right",
          width: 150,
        },
      ],

      //更多信息
      modalOne: false,
      formOne: {},
      //分账
      modalTwo: false,
      formTwo: {},
      uploadPercent: 0, //上传百分比
      uploadUrl: "", //上传地址
      uploadDir: "fenzhang", //上传位置
      uploadData: {}, //上传参数
      uploadList: [], //已上传文件列表
      //退款
      modalThree: false,
      refundsCode: null,
      refundsId: null,
      refundsMoney: null,
      loadingThree: false,
      loadingCheck: false,
      loadingCheckNum: 30,
    };
  },
  methods: {
    getDataList() {
      this.MyUtil.ajax()
        .post("/OrderController/getOrderListForWeb", this.param)
        .then((resData) => {
          this.data = resData.data;
          this.total = resData.total;
        });
    },
    changePage(page) {
      this.param.page = page;
      this.getDataList();
    },
    changePageSize(rows) {
      this.param.rows = rows;
      this.getDataList();
    },
    changeSelection(selection) {
      this.deleteIds = [];
      selection.forEach((element) => {
        this.deleteIds.push(element.id);
      });
    },
    search() {
      this.param.page = 1;
      this.getDataList();
    },

    showModalOne(row) {
      this.MyUtil.ajax()
        .post("/OrderController/getOrderDetail", {
          id: row.id,
        })
        .then((resData) => {
          this.formOne = resData;
          if (this.formOne && this.formOne.maxUrl) {
            this.formOne.urlArr = this.formOne.maxUrl.split(",");
          }
          if (this.formOne && this.formOne.sendMoneyUrl) {
            this.formOne.sendMoneyUrlArr = this.formOne.sendMoneyUrl.split(",");
          }
          this.modalOne = true;
        });
    },

    toHandleOrder(row) {
      let ids = [];
      if (row) {
        ids.push(row.id);
      } else {
        if (this.deleteIds.length < 1) {
          this.$Message.warning("请至少选择一条数据");
          return;
        }
        ids = this.deleteIds;
      }
      this.$Modal.confirm({
        title: "操作确认",
        content: "点击确认，以继续执行该操作！",
        onOk: () => {
          this.MyUtil.ajax()
            .post("/OrderController/handleOrderForWeb", {
              orderIdArray: ids,
            })
            .then((resData) => {
              this.$Message.success("操作成功");
              this.getDataList();
              this.deleteIds = [];
            });
        },
      });
    },

    toSendMoney(row) {
      let ids = [];
      if (row) {
        ids.push(row.id);
      } else {
        if (this.deleteIds.length < 1) {
          this.$Message.warning("请至少选择一条数据");
          return;
        }
        ids = this.deleteIds;
      }
      this.uploadList = [];
      this.formTwo.orderIdArray = ids;
      this.modalTwo = true;
    },
    handleBeforeUpload(file) {
      let fileName = file.name;
      let fileSuffix = fileName.substring(
        fileName.lastIndexOf("."),
        fileName.length
      );

      //获取oss文件上传授权
      return new Promise((resolve, reject) => {
        this.MyUtil.ajax()
          .post("/FileController/getOssAuthorize", {
            dir: this.uploadDir,
          })
          .then((resData) => {
            //设置上传参数
            this.uploadData = resData;
            //设置上传地址
            this.uploadUrl = resData.host;
            //设置存储路径
            this.uploadData.key =
              this.uploadDir + "/" + this.MyUtil.uuid() + fileSuffix;
            resolve(true);
          });
      });
    },
    handleOnProgress(event) {
      //设置上传进度
      if (event.percent) {
        this.uploadPercent = event.percent;
      }
    },
    handleOnSuccess(response, file) {
      //设置已上传文件
      this.uploadList.push({
        name: file.name,
        url: this.MyUtil.fileUrl + this.uploadData.key,
      });
    },

    sendMoneyForWeb() {
      if (this.uploadList.length <= 0) {
        this.$Message.warning("请上传分账凭证！");
        return;
      }
      this.formTwo.urlArray = [];
      this.uploadList.forEach((element) => {
        this.formTwo.urlArray.push(element.url);
      });

      //获取oss文件上传授权
      this.MyUtil.ajax()
        .post("/OrderController/sendMoneyForWeb", this.formTwo)
        .then((resData) => {
          this.$Message.success("操作成功");
          this.getDataList();
          this.deleteIds = [];
          this.modalTwo = false;
        });
    },

    showModalThree(row) {
      this.modalThree = true;
      this.refundsId = row.id;
      this.refundsCode = row.code;
      this.refundsMoney = row.totalPrice;
    },

    refundsOrder() {
      if (this.refundsMoney > 0) {
      } else {
        this.$Message.warning("请输入退款金额！");
        return;
      }

      this.loadingThree = true;
      this.MyUtil.ajax()
        .post("/OrderController/refundsOrder", {
          id: this.refundsId,
          refund: this.refundsMoney,
        })
        .then((resData) => {
          this.loadingThree = false;
          this.daojishi();
        });
      setTimeout(() => {
        this.loadingThree = false;
      }, 5000);
    },

    daojishi() {
      this.loadingCheck = true;
      this.loadingCheckNum = 30;
      let timer = setInterval(() => {
        this.loadingCheckNum--;
        if (
          this.loadingCheckNum == 21 ||
          this.loadingCheckNum == 11 ||
          this.loadingCheckNum == 1
        ) {
          this.MyUtil.ajax()
            .post("/OrderController/getOrderDetail", {
              id: this.refundsId,
            })
            .then((resData) => {
              if (resData.status == 101) {
                this.getDataList();
                this.$Message.success("退款成功！");
                this.loadingCheck = false;
                this.modalThree = false;
                clearInterval(timer);
              }
            });
        }
        if (this.loadingCheckNum <= 0) {
          this.loadingCheck = false;
          this.modalThree = false;
          clearInterval(timer);
        }
      }, 1000);
    },

    cancelOrder(row) {
      this.$Modal.confirm({
        title: "操作确认",
        content: "点击确认，以继续执行该操作！",
        onOk: () => {
          this.MyUtil.ajax()
            .post("/OrderController/cancelOrder", {
              id: row.id,
            })
            .then((resData) => {
              this.$Message.success("操作成功");
              this.getDataList();
            });
        },
      });
    },
  },
  created() {
    this.getDataList();
  },
};
</script>
